@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-sans bg-sand text-secondary;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold;
  }

  h1 {
    @apply text-5xl md:text-6xl;
  }

  h2 {
    @apply text-3xl md:text-4xl;
  }

  h3 {
    @apply text-2xl md:text-3xl;
  }

  p {
    @apply text-base md:text-lg;
  }
}

@layer components {
  .btn {
    @apply px-6 py-3 font-medium rounded-md transition-all duration-300;
  }

  .btn-primary {
    @apply bg-primary text-white hover:bg-opacity-90;
  }

  .container-section {
    @apply max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12;
  }

  .video-embed {
    @apply rounded-xl overflow-hidden;
  }
}

@layer utilities {
  .animated-cursor {
    @apply w-0 h-12 inline-block bg-primary animate-cursor-blink;
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.animate-cursor-blink {
  animation: blink 1s step-end infinite;
}
